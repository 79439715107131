<template>
  <div class="gallery">
    <VueSlickCarousel v-if="imagesLength" ref="c1" :asNavFor="c2" :settings="settings">
      <div v-for="(i, ind) in imagesToRender" :key="ind" class="main__image">
        <img class="product__image" :src="i" alt="" />
      </div>
    </VueSlickCarousel>
    <VueSlickCarousel
      v-if="imagesLength"
      ref="c2"
      :asNavFor="c1"
      :slidesToShow="imagesLength"
      :focusOnSelect="true"
    >
      <div v-for="(i, ind) in imagesToRender" :key="ind" class="sub__image">
        <img class="product__image" :src="i" alt="" />
      </div>
    </VueSlickCarousel>
    <VueSlickCarousel v-else ref="c1" :settings="settings">
      <div v-for="(i, ind) in 4" :key="ind" class="main__image">
        <img class="product__image" :src="image" alt="" />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  data() {
    return {
      obj: {},
      c1: null,
      c2: null,
      settings: {
        focusOnSelect: true,
        arrows: true,
        touchMove: true,
      },
    };
  },
  props: {
    image: {
      type: String,
    },
    images: {
      type: Array,
    },
  },
  components: {
    VueSlickCarousel,
  },
  computed: {
    imagesLength() {
      return this.images && this.images.length;
    },
    ref1() {
      return this.$refs && this.$refs.c1;
    },
    ref2() {
      return this.$refs && this.$refs.c2;
    },
    imagesToRender() {
      if (this.images && this.images.length) {
        return this.images;
      }
      return [];
    },
  },
  updated() {
    this.c1 = this.$refs.c1
    this.c2 = this.$refs.c2
  }
};
</script>

<style>
.gallery {
  width: 100%;
  max-width: 500px;
  padding: 50px;
}
.slick-prev:before,
.slick-next:before {
  color: #75b051;
  opacity: 1;
}

.slick-prev,
.slick-next {
  border-radius: 50%;
}

.main__image {
  max-width: 100%;
}
.sub__image {
  cursor: pointer;
  max-width: 50%;
}
.product__image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .gallery {
    margin: 0 auto;
  }
}

@media (max-width: 510px) {
  .gallery {
    max-width: 320px;
  }
}
</style>
